import React from "react";
import Team from "./Team";
export default function About() {
return (
        <>

          <section class="about_panel">
            <div className="textBox">
              <h1>In Progress</h1>
            </div>
            <div className="imgBox"></div>
          </section>



          <Team/>
        </>  
  );
}
